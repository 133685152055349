import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons"
import "./report-landing.scss"

export default function ReportLanding({postData}) {

    return (
        <>
            {/*  <!-- Home Blog Start Here --> */}
            <div id="rs-blog" className="rs-blog sec-spacer">
                <div className="container">
                    <div className="row">
                        {postData?.map(el => {
                            return (
                                <div className="col-md-3 col-sm-6 col-xs-6">
                                    <Link to={`/blog/${el?.slug}`}>
                                    <div className="single-blog-slide">
                                        <div className="images">
                                                <GatsbyImage
                                                image={getImage(el?.featuredImage?.node?.localFile)}
                                                alt={"Blog Image"}
                                                className=""
                                                />
                                        </div>
                                        <div className="blog-details">
                                            <span className="date">
                                                <FontAwesomeIcon className="pr-1" icon={faCalendarCheck} style={{width: "15px", height: "15px"}}/>
                                                {el?.date}</span>
                                            <h3><a href="#">{el?.title} </a></h3>
                                            <p dangerouslySetInnerHTML={{__html: el?.excerpt}}></p>
                                            <div className="read-more">
                                                <a href="#">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* <!-- Home Blog End Here --> */}
        </>
    )
}