import { Link } from "gatsby"
import React from "react"
const range = (start, end) => {
  let length = end - start + 1
  return Array.from({ length }, (_, idx) => idx + start)
}
function Pagination({ paginationData, base }) {
  const siblingCount = 5
  const totalPageNumbers = siblingCount + 5
  const currentPage = paginationData.currentPage
  const totalPageCount = paginationData.numPages

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)
  const shouldShowLeftDots = leftSiblingIndex > 2
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2
  const Numrange = () => {
    if (currentPage < 5) {
      return (
        <div>
          {Array.from({ length: 5 }, (_, i) => (
            <Link
              key={`pagination-number${i + 1}`}
              to={`/${base}/${i === 0 ? "" : i + 1}`}
              className="pagination-number pagination-comps"
            >
              <li>{i + 1}</li>
            </Link>
          ))}
          <span>...</span>
          <Link
            className="pagination-number pagination-comps"
            to={`/${base}/${paginationData.numPages}`}
          >
            {paginationData.numPages}
          </Link>
        </div>
      )
    }
    if (currentPage >= 5 && currentPage + 5 < paginationData.numPages) {
      return (
        <div>
          <Link className="pagination-number pagination-comps" to={`/${base}`}>
            1
          </Link>
          <span>...</span>
          {Array.from({ length: currentPage + 3 - currentPage }, (_, i) => (
            <Link
              key={`pagination-number${currentPage + i + 1}`}
              to={`/${base}/${
                currentPage + i === 0 ? "" : currentPage + i + 1
              }`}
              className="pagination-number pagination-comps"
            >
              <li>{currentPage + i + 1}</li>
            </Link>
          ))}
          <span>...</span>
          <Link
            className="pagination-number pagination-comps"
            to={`/${base}/${paginationData.numPages}`}
          >
            {paginationData.numPages}
          </Link>
        </div>
      )
    }

    if (currentPage + 5 >= paginationData.numPages) {
      const arr = []
      for (
        let i = paginationData.numPages;
        i > paginationData.numPages - 5;
        --i
      ) {
        arr.unshift(i)
      }
      return (
        <div>
          <Link className="pagination-number pagination-comps" to={`/${base}`}>
            1
          </Link>
          <span>...</span>
          {arr.map(i => {
            return (
              <Link
                key={`pagination-number${i}`}
                to={`/${base}/${i}`}
                className="pagination-number pagination-comps"
              >
                <li>{i}</li>
              </Link>
            )
          })}
          {/* {Array.from(
            { length: paginationData.numPages - (currentPage) },
            (_, i) => (
              <Link
                key={`pagination-number${currentPage + i + 1}`}
                to={`/${base}/${
                  currentPage + i === 0 ? "" : currentPage + i + 1
                }`}
                className="pagination-number pagination-comps"
              >
                <li>{currentPage + i + 1}</li>
              </Link>
            )
          )} */}
        </div>
      )
    }
  }
    return (
      <div className="col-sm-12" style={{marginBottom: "30px"}}>
        {/* <h1>Current page: {paginationData.currentPage}</h1> */}
        <div className="default-pagination text-center">
          <ul style={{display: "flex", justifyContent: "center", height: "100%", alignItems: "center"}}>
            {!paginationData?.isFirst && (
              <Link to={`/${base}/${paginationData?.prevPage}`} rel="prev">
                <li className="pagination-comps pagination-title">
                  ← Prev
                </li>
              </Link>
            )}
  
            <Numrange />
            {!paginationData?.isLast && (
              <Link to={`/${base}/${paginationData?.nextPage}`} rel="next">
                <li className="pagination-comps pagination-title">Next →</li>
              </Link>
            )}
          </ul>
        </div>
      </div>
    )
}

export default Pagination
