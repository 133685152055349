import React, {useEffect} from "react"
import { graphql, Link } from "gatsby"
import ReportLanding from "../components/report-landing/report-landing"
import Pagination from "../components/Pagination/Pagination"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

function BlogArchive({ data, pageContext }) {
  const postData = data.allWpPost?.nodes
  const imageData = data?.wp?.siteSettings?.siteSettings?.globalInnerHero

  //  page context data
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : currentPage - 1
  const nextPage = currentPage + 1
  // pagination data
  const paginationData = {
    currentPage,
    numPages,
    isFirst,
    isLast,
    prevPage,
    nextPage,
  }
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh();
  }, []);
  return (
    <DefaultLayout data={data}>
      <div className="rs-breadcrumbs sec-color">
        <GatsbyImage
          image={getImage(imageData?.backgroundImage?.localFile)}
          alt={"Saigon Raiders Club"}
          className="inner-img"
        />
        <div className="breadcrumbs-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="5"
              >
                <h1 className="page-title">{pageContext.name}</h1>
                <ul>
                  <li>
                    <a className="active" href="/blog">
                      News Home &#187;{" "}
                    </a>
                  </li>
                  <li> News</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportLanding postData={postData} />
      {pageContext?.numPages > 1 ?
      <Pagination paginationData={paginationData} base={`blog/${pageContext?.slug}`} />
        : ""
      }
      
    </DefaultLayout>
  )
}
export const query = graphql`
  query($skip: Int!, $limit: Int!, $slug: String!) {
    wp {
      siteSettings {
        siteSettings {
          globalInnerHero {
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, height: 380)
                }
              }
            }
          }
        }
      }
    }
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug : { eq: $slug } } } }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        uri
        title
        date(formatString: "DD MMMM, YYYY")
        slug
        customFields {
          date
          score
          time
          title
        }
        excerpt
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 450, height: 383)
              }
            }
          }
        }
      }
    }
  }
`
export default BlogArchive
